<template>
  <vuestic-widget :loading="loading">
    <template slot="header">
      <div class="w-100 d-flex justify-content-between align-items-center">
        <div class="title">My Installs
          <span class="col availabe-numbers">
            ( Archived )
          </span>
        </div>
        <div class="d-flex justify-content-end align-items-center">
          <div class="mr-2">
            <b-nav pills>
              <b-nav-item><router-link :to="{ name: 'business.installs.active' }">Active</router-link></b-nav-item>
              <b-nav-item active><router-link
                  :to="{ name: 'business.installs.archived' }">Archived</router-link></b-nav-item>
            </b-nav>
          </div>
          <router-link :to="{ name: 'business.installs.create' }" class="btn btn-primary btn-sm float-right"
            title="Add Install">
            <span>Add Install <i class="fa fa-plus"></i></span>
          </router-link>
        </div>
      </div>
    </template>

    <datatable v-bind="tableConfig" class="le-datatable" />

    <vuestic-modal :isOpen="isOpenModalRestore" @ok="handleRestoreInstall" @cancel="closeModalRestore" okText="Restore"
      cancelText="Cancel">
      <span slot="title">Restore Install</span>

      <div>Confirm you want to restore install <span
          v-if="selectedInstall && selectedInstall.properties && selectedInstall.properties.website">{{
            selectedInstall.properties.website }}</span>
        ?</div>
      <div v-if="selectedInstall && !selectedInstall.number">
        <div>Please note that Install does not have a phone number assigned.</div>
        <div>After restoration edit Install and assign phone number by specifying Area Code.</div>
      </div>
    </vuestic-modal>

    <vuestic-modal :isOpen="isOpenModalDelete" @ok="handleDeleteInstall" @cancel="closeModalDelete" okText="Delete"
      okClass="btn btn-danger" cancelText="Cancel" :closeOnOk="false" :processing="processing">
      <span slot="title" class="text-danger font-weight-bold">Delete Install</span>

      <div>Confirm you want to delete the install<span v-if="selectedInstall"> for <b>{{ selectedInstall.name }}</b></span>?
      </div>
      <div>The install will be permanently deleted.</div>
    </vuestic-modal>

  </vuestic-widget>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import ActionsArchived from './Actions/ActionsArchived'
import ColumnNumber from './Columns/Number'
import ColumnWidgetIsSet from './Columns/WidgetIsSet'
import CreateInstall from './Actions/CreateInstall'
import components from '../../common/tables/comps'

export default {
  components: {
    CreateInstall,
  },

  data() {
    return {
      loading: false,
      isOpenModalRestore: false,
      selectedInstall: null,
      isOpenModalDelete: false,
      tableConfig: {
        supportBackup: false,
        supportNested: false,
        HeaderSettings: false,
        tblClass: 'table-bordered',
        tblStyle: 'color: #666',
        pageSizeOptions: [10, 25, 50, 100],
        columns: (() => {
          const cols = [
            { title: 'ID', field: 'id', label: 'Install ID', sortable: true, visible: true, },
            { title: 'website', sortable: true, field: 'website', thComp: 'FilterTh', tdComp: 'PropertyValue', tdStyle: { fontStyle: 'normal' }, },
            { title: 'Number', sortable: false, visible: true, tdClass: 'center', tdComp: ColumnNumber, },
            { title: 'Set', field: 'is_used', sortable: false, visible: true, tdClass: 'center', tdComp: ColumnWidgetIsSet, },
            { title: 'Action', tdComp: ActionsArchived, visible: 'true', tdClass: 'center', },
          ]
          return cols
        })(),
        data: [],
        total: 0,
        // selection: [],  //if this is present the row selector shows up..
        summary: {},
        query: {},
        // any other staff that you want to pass to dynamic components (thComp / tdComp / nested components)
        xprops: {
          eventbus: new Vue(),
        },
      }
    }
  },

  watch: {
    'tableConfig.query': {
      handler: function (newValue, oldValue) {
        this.onQueryChange(newValue)
      },
      deep: true,
    },
    tableData: {
      handler: function (newValue, oldValue) {
        if (newValue != oldValue)
          this.tableConfig.data = newValue;
      },
      deep: true,
    },
    tableTotal: {
      handler: function (newValue, oldValue) {
        if (newValue != oldValue)
          this.tableConfig.total = newValue;
      },
      deep: true,
    }
  },

  created() {
    this.tableConfig.xprops.eventbus.$on('openRestoreModal', row => {
      this.openModalRestore(row)
    })
    this.tableConfig.xprops.eventbus.$on('openDeleteModal', row => {
      this.openModalDelete(row)
    })
  },

  mounted() {
    if (!this.didFetch) {
      this.loadData();
    }
    this.tableConfig.data = this.tableData;
    this.tableConfig.total = this.tableTotal;
  },

  methods: {
    loadData() {
      this.$store.dispatch('install/fetchAllInstalls')
    },

    onQueryChange(query) {
      const page = Math.floor(query.offset / query.limit) + 1
      const param = {
        page,
        perPage: query.limit
      }
      this.$store.commit('install/filterList', param)
    },

    handleDeleteInstall() {
      this.$store.dispatch('install/deleteInstall', this.selectedInstall.id)
      .then((res) => {
        this.closeModalDelete()
        this.loadData()
      }).catch(e => {
        console.warn(e)
      })
    },

    closeModalDelete() {
      this.isOpenModalDelete = false
      this.selectedInstall = null
    },

    handleRestoreInstall() {
      this.loading = true
      const id = this.selectedInstall.id
      this.closeModalRestore()
      this.$api.restoreInstall(id).then(({ data }) => {
        if (data.success) {
          this.showToast(data.data.message)
          this.handleQueryChange()
          this.$router.push({ name: 'business.installs.active' });
        } else {
          this.showToast(data.data.message)
        }
        this.loading = false
      }).catch(e => {
        console.warn(e)
        this.loading = false
      })
    },

    openModalDelete(row) {
      this.isOpenModalDelete = true
      this.selectedInstall = row
    },

    openModalRestore(install) {
      this.isOpenModalRestore = true
      this.selectedInstall = install
    },

    closeModalRestore() {
      this.isOpenModalRestore = false
      this.selectedInstall = null
    },

    ...mapMutations('install', {
      filterList: 'filterList'
    })
  },
  computed: {
    tableData() {
      return this.$store.getters['install/tableData']('archived');
    },
    tableTotal() {
      return this.$store.getters['install/tableTotal']('archived');
    },
    ...mapState('install', [
      'fetching',
      'didFetch',
      'processing',
      'page',
      'perPage',
    ]),
  }
}
</script>
